import { MyLogDefinition } from './log.my.d'

export default {
  my: {
    page_id: '/my',
    action_id: {
      tapSetting: 'tap.setting',
      tapNotification: 'tap.notification',
      tapMyinfo: 'tap.myinfo',
      tapPoint: 'tap.point',
      tapCarprofile: 'tap.carprofile',
      tapReview: 'tap.review',
      viewReviewBanner: 'view.review_banner',
      tapReviewBanner: 'tap.review_banner',
      viewMyReviewReaction: 'view.my_review_reaction',
      viewAvailableReview: 'view.available_review',
      tapAvailableReview: 'tap.available_review',
      viewWritingReview: 'view.writing_review',
      tapWritingReview: 'tap.writing_review',
      viewAvailableReviewMore: 'view.available_review_more',
      tapAvailableReviewMore: 'tap.available_review_more',
      viewAd: 'view_ad',
      tapAd: 'tap.ad',
      tapUsage: 'tap.usage',
      tapPay: 'tap.pay',
      tapCoupon: 'tap.coupon',
      tapWholeService: 'tap.whole_service',
      tapBizPlace: 'tap.biz_place',
      tapTimemachine: 'tap.timemachine',
      tapLaboratory: 'tap.laboratory',
      tapVoc: 'tap.voc',
      tapCommunicationEvent: 'tap.communication_event',
      tapPoiProposal: 'tap.poi_proposal',
      tapPolicy: 'tap.policy',
      tapBizPlaceBanner: 'tap.biz_place_banner',
      tapBusinessInfo: 'tap.business_info',
      viewProfileTooltip: 'view.profile_tooltip',
      tapProfileTooltip: 'tap.profile_tooltip',
    },
  },
} as MyLogDefinition
