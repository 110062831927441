import { AdType } from '@tmap-web-lib/remote-api-client/frontman'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useGlobalLoadingSpinner } from '@/Providers'
import { sendEventLog } from '@/features/log/log.fn'
import MyLogDefinition from '@/features/log/log.my'
import { TrackingValues, useAdsManager, useIntersectionObserver, useOpenService } from '@/hooks'
import { useGetAdsTracking } from '@/react-query'
import { bannerAutoplayOptions, numberPaginationOptions } from '@/types'

import * as style from './adsBanner.css'

interface Props {
  data: AdType[] | null
  isLoading: boolean
}

function AdsBanner(props: Props) {
  const { data, isLoading } = props
  const openService = useOpenService()
  const { action_id } = MyLogDefinition.my
  const { ref, isView, top } = useIntersectionObserver(
    (entry, observer) => {
      observer.unobserve(entry.target)
    },
    { threshold: 0.9 }
  )

  const currentTop = ref && ref.current && ref.current.getBoundingClientRect().top
  const { initTrackingValues } = useAdsManager()
  const showSpinner = useGlobalLoadingSpinner()
  const [initialSlideActiveIndex, setInitialSlideActiveIndex] = useState<number | null>(null)
  const [slideActiveIndex, setSlideActiveIndex] = useState<number | null>(null)
  const [trackingUrl, setTrackingUrl] = useState('')
  const [vimpressionId, setVimpressionId] = useState('')
  const [materialId, setMaterialId] = useState('')
  const [orientation, setOrientation] = useState(1)
  const [isVimpValuesReady, setIsVimpValuesReady] = useState(false)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const [isOnResume, setIsOnResume] = useState(true)

  const prevIndex = useRef<number | null>(null)

  const { refetch: refetchAdsTracking } = useGetAdsTracking({
    enabled: isVimpValuesReady && isOnResume && isIntersecting,
    url: trackingUrl,
    orientation: orientation,
    materialId: materialId,
    vimpressionId: vimpressionId,
    showSpinner,
  })

  const setTrackingData = useCallback((data: TrackingValues) => {
    setTrackingUrl(data.trackingUrl)
    setOrientation(data.orientation)
    setVimpressionId(data.vimpressionId!)
    setMaterialId(data.materialId)
  }, [])

  const handleBannerClick = useCallback(
    (ad: AdType) => {
      if (ad.landingUrl) {
        sendEventLog(action_id?.tapAd, { unit: ad.adId, text: 'internal' })
        openService(ad.landingUrl)
      }
    },
    [openService, action_id?.tapAd]
  )

  const renderSwiperSlide = useCallback(
    (ad: AdType, index: number) => {
      return (
        <SwiperSlide key={index}>
          <button
            className={style.bannerItem}
            disabled={!ad.landingUrl}
            onClick={() => {
              const trackingInfo = ad.trackingEventUrls.find(
                (trackingInfo) => trackingInfo.key === 'click'
              )
              const material = ad?.materials[0]
              if (trackingInfo && material) {
                setTrackingUrl(trackingInfo.value)
                setMaterialId(material.id)
                handleBannerClick(ad)
              }
            }}
          >
            {ad.materials[0].url && (
              <img
                src={ad.materials[0].url}
                className={style.bannerImage}
                alt=""
              />
            )}
          </button>
        </SwiperSlide>
      )
    },
    [handleBannerClick]
  )

  const renderSwipe = useCallback(() => {
    if (!isLoading && data && data.length > 0) {
      return (
        <div ref={ref}>
          <Swiper
            autoHeight={false}
            slidesOffsetBefore={0}
            centeredSlides={true}
            slidesPerView={'auto'}
            updateOnWindowResize={true}
            resizeObserver={true}
            watchOverflow={false}
            autoplay={data.length > 1 ? bannerAutoplayOptions : false}
            pagination={numberPaginationOptions}
            loop={data.length > 2} // slide 개수가 3개 미만일 때 not enough for loop mode 오류 발생하여 스와이프 동작 제대로 안 됨
            touchRatio={data.length > 1 ? 1 : 0}
            onAutoplayPause={(swiper) => swiper.autoplay.start()}
            onInit={(e) => {
              setInitialSlideActiveIndex(e.realIndex)
              prevIndex.current = e.realIndex
            }}
            onSlideChange={(e) => {
              if (isView && prevIndex.current !== e.realIndex) {
                initTrackingValues(data[e.realIndex]).then((data) => {
                  if (data) {
                    setTrackingData(data)
                  }
                })
                setSlideActiveIndex(e.realIndex)
                prevIndex.current = e.realIndex
              }
            }}
          >
            {data.map((ad, index) => {
              return ad && renderSwiperSlide(ad, index)
            })}
          </Swiper>
        </div>
      )
    }
  }, [data, isLoading, initTrackingValues, isView, ref, renderSwiperSlide, setTrackingData])

  useEffect(() => {
    if (trackingUrl && orientation && materialId && vimpressionId) {
      setIsVimpValuesReady(true)
    }
  }, [trackingUrl, orientation, materialId, vimpressionId])

  useEffect(() => {
    if (data) {
      if (data.length < 2) {
        initTrackingValues(data[0]).then((data) => {
          if (data) {
            setTrackingData(data)
          }
        })
      } else {
        if (initialSlideActiveIndex !== null) {
          initTrackingValues(data[initialSlideActiveIndex]).then((data) => {
            if (data) {
              setTrackingData(data)
              setInitialSlideActiveIndex(null)
            }
          })
        }
      }
    }
  }, [data, initTrackingValues, setTrackingData, initialSlideActiveIndex])

  useEffect(() => {
    if (currentTop && top && currentTop >= top) {
      setIsIntersecting(true)
    }
  }, [currentTop, top])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onPause', () => {
      setIsOnResume(false)
    })
  }, [])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onResume', () => {
      setIsOnResume(true)
    })
  }, [])

  useEffect(() => {
    if (trackingUrl.includes('click')) {
      refetchAdsTracking()
    }
  }, [trackingUrl, refetchAdsTracking])

  useEffect(() => {
    if (slideActiveIndex === null) return
  }, [slideActiveIndex])

  if (!data || !data.length) return null

  return <div className={style.wrapper}>{renderSwipe()}</div>
}

export { AdsBanner }
