export const KEYS = {
  // 유저프로필
  USER_POINT: () => ['userPoint'],
  USER_INFO: () => ['userInfo'],
  // 차량
  VEHICLE_PARKING: () => ['vehicleParking'],
  // 리뷰
  REVIEW: () => ['review'],
  // 광고
  BANNER_TRACKING_LIST: (vimpressionId: string) => ['bannerList', vimpressionId],
  BANNER_LIST: () => ['bannerList'],
  // 전체서비스
  ALL_SERVICES: () => ['allServices'],
  FAMILY_APPS: () => ['familyApps'],
}
