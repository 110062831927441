import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { FamilyAppsRes } from '@tmap-web-lib/remote-api-client/dist/types/frontman/apis/common/channel-app/get-family-apps'
import { getFamilyApps } from '@tmap-web-lib/remote-api-client/frontman'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { AxiosError } from 'axios'

import { KEYS } from '@/react-query'

function useGetFamilyApps(): UseQueryResult<FamilyAppsRes, AxiosError> {
  const appVersion = TmapApp.env.isInApp ? TmapApp.env.appVersion : '10.9.0'
  // 서버에서 대문자 IOS로만 받을 수 있도록 되어있어서 FE에서 대문자로 요청
  // 서버에서 처리하려했으나 GC 문제로 인해 당장 해결이 어려운 상황
  const osType = TmapApp.env.isInApp ? (TmapApp.env.isAndroid ? 'AND' : 'IOS') : 'iOS'

  return useQuery<FamilyAppsRes, AxiosError>([KEYS.FAMILY_APPS()], () =>
    getFamilyApps({ appVersion, osType }).then(({ data }) => data)
  )
}

export { useGetFamilyApps }
