import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { UserInfoData, getUserInfo } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'

import { KEYS } from '@/react-query'

function useGetUserInfo(): UseQueryResult<UserInfoData, AxiosError> {
  return useQuery<UserInfoData, AxiosError>([KEYS.USER_INFO()], () => {
    return getUserInfo().then(({ data }) => data)
  })
}

export { useGetUserInfo }
