import { RedDotListData, TmapApp } from '@tmap-web-lib/tmap-app-interface'
import dayjs from 'dayjs'
import Image from 'next/image'
import process from 'process'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import BizBanner from '@/assets/images/img_my_menu_biz.svg'
import { sendEventLog } from '@/features/log/log.fn'
import MyLogDefinition from '@/features/log/log.my'
import { getSettingsState } from '@/features/store/settings'
import { useOpenService, useOpenServiceUrl } from '@/hooks'
import { BizPlaceBannerType, ServiceListType, envServiceUrl } from '@/utils'

import * as styles from './serviceList.css'

interface Props {
  redDotList: Pick<RedDotListData, 'itemGroup' | 'item'>[]
}

function ServiceList(props: Props) {
  const { redDotList } = props
  const { remoteConfigServiceList, bizPlaceBanner } = useSelector(getSettingsState)
  const openService = useOpenService()
  const openServiceUrl = useOpenServiceUrl()
  const { action_id } = MyLogDefinition.my
  const env = process.env.NEXT_PUBLIC_TARGET
  const [serviceList, setServiceList] = useState<ServiceListType[] | null>(null)
  const [bizPlaceStatus, setBizPlaceStatus] = useState<BizPlaceBannerType | null>(null)

  const handleClick = useCallback(
    (serviceUrl: string, itemGroup?: string, itemName?: string) => {
      if (itemGroup !== 'COMMUNICATION' && itemGroup !== 'HOME') {
        if (itemGroup && itemName) {
          TmapApp.updateRedDotList({
            updateData: [
              {
                itemGroup: itemGroup,
                item: itemName,
                displayType: 'REDDOT',
                readDateTime: dayjs().format('YYYYMMDDHHmmss'),
              },
            ],
          })
          TmapApp.updateAllRedDots()
        }
      }
      if (serviceUrl === 'payment') {
        TmapApp.startPaymentActivity({ url: '/' })
      } else if (serviceUrl === 'tmap://cs') {
        if (TmapApp.env.isAndroid) {
          // AOS에서 고객센터는 이미 가로모드 미지원
          openService(serviceUrl)
        } else {
          openService(serviceUrl, { portraitonly: 'true' })
        }
      } else if (serviceUrl.startsWith('tmap://')) {
        openService(serviceUrl)
      } else {
        if (serviceUrl.startsWith('https://')) {
          openServiceUrl(serviceUrl, true)
        } else {
          openServiceUrl(serviceUrl, true, true)
        }
      }
    },
    [openService, openServiceUrl]
  )

  const handleClickTmapBizInfo = useCallback(() => {
    switch (env) {
      case 'dev':
        openServiceUrl(envServiceUrl.tmapBizInfo.dev, true)
        break
      case 'dtg':
        openServiceUrl(envServiceUrl.tmapBizInfo.stg, true)
        break
      case 'stg':
        openServiceUrl(envServiceUrl.tmapBizInfo.stg, true)
        break
      case 'rtg':
        openServiceUrl(envServiceUrl.tmapBizInfo.prd, true)
        break
      case 'prd':
        openServiceUrl(envServiceUrl.tmapBizInfo.prd, true)
        break
    }
  }, [env, openServiceUrl])

  useEffect(() => {
    if (remoteConfigServiceList.length) {
      setServiceList(JSON.parse(remoteConfigServiceList))
    }
  }, [remoteConfigServiceList])

  useEffect(() => {
    if (bizPlaceBanner.length) {
      setBizPlaceStatus(JSON.parse(bizPlaceBanner))
    }
  }, [bizPlaceBanner])

  return (
    <div className={styles.wrapper}>
      {serviceList?.map((group: ServiceListType) => {
        return (
          <div key={group.groupCode}>
            <p className={styles.groupTitle}>{group.groupName}</p>
            {group.groupList.map((service) => {
              const isRedDot = redDotList.find((redDot) => {
                if (
                  (redDot.item.startsWith('EVENT') || redDot.item.startsWith('NOTICE')) &&
                  service.redDotItem === 'COMMUNICATION'
                ) {
                  return true
                } else {
                  if (
                    redDot.item === service.redDotItem ||
                    redDot.itemGroup === service.redDotItem
                  ) {
                    return true
                  }
                }
              })
              return (
                <button
                  key={service.name}
                  className={`${styles.serviceBtn}`}
                  onClick={() => {
                    if (isRedDot) {
                      sendEventLog(service.log_action_id, { unit: 'REDDOT' })
                      handleClick(service.url, isRedDot.itemGroup, isRedDot.item)
                    } else {
                      sendEventLog(service.log_action_id)
                      handleClick(service.url)
                    }
                  }}
                >
                  <div
                    className={`${styles.reddotWrapper}  ${isRedDot ? styles.redDotVariants.reddot : ''}`}
                  >
                    {service.name}
                  </div>
                </button>
              )
            })}
          </div>
        )
      })}
      {bizPlaceStatus?.isShow && (
        <button
          className={styles.bizPlaceBannerWrapper}
          onClick={() => {
            sendEventLog(action_id?.tapBizPlaceBanner)
            handleClick(bizPlaceStatus.url)
          }}
        >
          <div>
            <p className={styles.bizPlaceTitle}>비즈 플레이스</p>
            <p>티맵에 업체 등록하고 관리하기</p>
          </div>
          <Image
            src={BizBanner}
            alt={''}
          />
        </button>
      )}

      <button
        className={styles.tmapBizInfo}
        onClick={() => {
          sendEventLog(action_id?.tapBusinessInfo)
          handleClickTmapBizInfo()
        }}
      >
        티맵모빌리티(주) 사업자정보
      </button>
    </div>
  )
}

export { ServiceList }
