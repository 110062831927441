import { UseQueryResult, useQuery } from '@tanstack/react-query'
import {
  PointsSummaryResponse,
  getTmapPayPointsSummary,
} from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { useSelector } from 'react-redux'

import { mockHost } from '@/apis'
import { STORAGE_MOCK_ENABLED, getSettingsState } from '@/features/store/settings'
import { KEYS } from '@/react-query'
import { storageUtil } from '@/utils'

interface Props {
  useErrorBoundary?: boolean
}

function useGetTmapPayPointsSummary(
  props: Props
): UseQueryResult<PointsSummaryResponse, AxiosError> {
  const { isMockEnabled } = useSelector(getSettingsState)

  return useQuery<PointsSummaryResponse, AxiosError>(
    [KEYS.USER_POINT()],
    async () => {
      const mock = storageUtil.getItem(STORAGE_MOCK_ENABLED)
      if (mock ? JSON.parse(mock) : isMockEnabled) {
        const { data } = await mockHost.get<PointsSummaryResponse>('point-api/v2/points/summary')
        return data
      } else {
        const { data } = await getTmapPayPointsSummary()
        return data
      }
    },
    {
      useErrorBoundary: props?.useErrorBoundary ?? true,
    }
  )
}

export { useGetTmapPayPointsSummary }
