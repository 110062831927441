import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendEventLog } from '@/features/log/log.fn'
import MyLogDefinition from '@/features/log/log.my'
import {
  STORAGE_TOOLTIP_CLICKED,
  getSettingsState,
  setToolTipClicked,
} from '@/features/store/settings'
import { storageUtil } from '@/utils'

import * as styles from './tooltip.css'

interface TooltipProps extends PropsWithChildren {
  content: string
}

function Tooltip({ content, children }: TooltipProps) {
  const { isTooltipClicked } = useSelector(getSettingsState)
  const { action_id } = MyLogDefinition.my
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [isHiding, setIsHiding] = useState(false)

  const handleClick = useCallback(() => {
    setIsHiding(true)
    dispatch(setToolTipClicked(true))
    sendEventLog(action_id?.tapProfileTooltip)
    setTimeout(() => setVisible(false), 200)
  }, [dispatch, action_id?.tapProfileTooltip])

  useEffect(() => {
    if (isTooltipClicked || JSON.parse(storageUtil.getItem(STORAGE_TOOLTIP_CLICKED) as string)) {
      setTimeout(() => setVisible(false), 200)
    } else {
      setTimeout(() => setVisible(true), 500)
    }
  }, [isTooltipClicked])

  useEffect(() => {
    if (visible) {
      sendEventLog(action_id?.viewProfileTooltip)
    }
  }, [visible, action_id?.viewProfileTooltip])

  return (
    <>
      {children}
      {visible && (
        <button
          className={`${styles.tooltip} ${visible ? 'visible' : ''} ${isHiding ? 'hidden' : ''}`}
          onClick={handleClick}
        >
          {content}
        </button>
      )}
    </>
  )
}

export default Tooltip
