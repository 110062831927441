import { useQuery } from '@tanstack/react-query'
import { AdsTrackingParams, getAdsTracking } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'

import { Options } from '@/Providers/global-loading-spinner-provider/GlobalLoadingSpinnerProvider'

import { KEYS } from '../keys'

interface Props extends AdsTrackingParams {
  enabled: boolean
  useErrorBoundary?: boolean
  showSpinner: <T>(promise: Promise<T>, options?: Options) => Promise<T>
}

function useGetAdsTracking(props: Props) {
  const { url, materialId, orientation, vimpressionId, showSpinner } = props

  return useQuery<void, AxiosError>(
    [KEYS.BANNER_TRACKING_LIST(vimpressionId)],
    async () => {
      return showSpinner(
        getAdsTracking({ url, materialId, orientation, vimpressionId }).then(({ data }) => data)
      )
    },
    {
      enabled: props.enabled,
      useErrorBoundary: props.useErrorBoundary ?? true,
    }
  )
}

export { useGetAdsTracking }
