import { AutoplayOptions, PaginationOptions } from 'swiper/types'

const bannerAutoplayOptions: AutoplayOptions = {
  delay: 2500,
  disableOnInteraction: false,
}

const bulletPaginationOptions: PaginationOptions = {
  clickable: false,
  bulletActiveClass: 'selected',
  bulletClass: 'swiper_indicator_bullet',
  modifierClass: 'swiper_indicator_',
  type: 'bullets',
}

const dotPaginationOptions: PaginationOptions = {
  clickable: false,
  bulletActiveClass: 'selected',
  bulletClass: 'swiper_indicator_dot',
  modifierClass: 'swiper_indicator_',
  type: 'bullets',
}

const numberPaginationOptions: PaginationOptions = {
  clickable: false,
  lockClass: 'swiper_indicator_lock',
  modifierClass: 'swiper_indicator_',
  renderCustom: function (S, current, total) {
    if (total > 1) {
      return `<div class="custom-pagination"><span class="custom-pagination current">${current}</span>/${total}</div>`
    } else {
      return ''
    }
  },
  type: 'custom',
}

const tabPaginationOptions: PaginationOptions = {
  el: '.card_tab_body',
  clickable: true,
  modifierClass: 'swiper_tab_',
  bulletClass: 'btn_tab_default',
  bulletActiveClass: 'btn_tab_primary',
  type: 'bullets',
}

export {
  bannerAutoplayOptions,
  bulletPaginationOptions,
  numberPaginationOptions,
  tabPaginationOptions,
  dotPaginationOptions,
}
