import Image from 'next/image'
import { useCallback } from 'react'

import { sendEventLog } from '@/features/log/log.fn'
import MyLogDefinition from '@/features/log/log.my'
import { useOpenService } from '@/hooks'

import * as style from './vehicleInfo.css'

export interface VehicleInfoProps {
  carNumber: string | null
  vehicleType: string
  fuelType: string
  isParking: boolean | null
}

function VehicleInfo(props: VehicleInfoProps) {
  const { carNumber, vehicleType, fuelType, isParking } = props
  const openService = useOpenService()
  const { action_id } = MyLogDefinition.my

  const handleClick = useCallback(() => {
    const custom = { car_number: carNumber ? 'Y' : 'N' }
    sendEventLog(action_id?.tapCarprofile, custom)
    openService('tmap://carprofile')
  }, [openService, action_id?.tapCarprofile, carNumber])

  return (
    <button
      className={style.wrapper}
      onClick={handleClick}
    >
      <div className={style.carInfo}>
        <p className={`${style.carInfo} ${carNumber ? 'number' : 'text'}`}>
          {carNumber ? carNumber : '내 차 등록하고 차량 관리 받기'}
        </p>
        <span>
          {vehicleType ? vehicleType : null}
          {fuelType ? (
            <>
              &nbsp;&nbsp;<span className={style.layer}>|</span>&nbsp;&nbsp;{fuelType}
            </>
          ) : null}
          {isParking ? (
            <>
              &nbsp;&nbsp;<span className={style.layer}>|</span>&nbsp;&nbsp;티맵주차
            </>
          ) : null}
        </span>
      </div>
      <Image
        src={require('../../../../../public/assets/images/ico_arrow_right.svg').default}
        alt=""
      />
    </button>
  )
}

export { VehicleInfo }
