export const vehicleModelTypes: Record<string, string>[] = [
  {
    model: 'CT_SMALL_TRUCK',
    name: '소형화물차',
  },
  {
    model: 'CT_NORMAL',
    name: '승용차',
  },
  {
    model: 'CT_MIDDLE',
    name: '중형차',
  },
  {
    model: 'CT_LARGE',
    name: '대형차',
  },
  {
    model: 'CT_TRUCK',
    name: '대형화물차',
  },
  {
    model: 'CT_SPECIAL',
    name: '특수화물차',
  },
  {
    model: 'CT_COMPACT',
    name: '경차',
  },
  {
    model: 'CT_MOTORCYCLE',
    name: '이륜차',
  },
]

// default 휘발유
export const fuelTypes: Record<string, string> = {
  FT_GAS: '휘발유',
  FT_DIESEL: '경유',
  FT_LPG: 'LPG',
  FT_GASPM: '고급휘발유',
  FT_EV: '전기',
  FT_HYD: '수소',
  FT_H2: '수소',
}
