import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { UserContextRes, getUserContext_v2 } from '@tmap-web-lib/remote-api-client/frontman'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { AxiosError } from 'axios'
import { useSelector } from 'react-redux'

import { mockHost } from '@/apis'
import { STORAGE_MOCK_ENABLED, getSettingsState } from '@/features/store/settings'
import { KEYS } from '@/react-query'
import { storageUtil } from '@/utils'

function useGetAllServices(): UseQueryResult<UserContextRes, AxiosError> {
  const { isMockEnabled } = useSelector(getSettingsState)
  const appVersion = TmapApp.env.isInApp ? TmapApp.env.appVersion : '10.9.0'
  const osType = TmapApp.env.isInApp ? (TmapApp.env.isAndroid ? 'AND' : 'iOS') : 'iOS'

  return useQuery<UserContextRes, AxiosError>(
    [KEYS.ALL_SERVICES()],
    async () => {
      const mock = storageUtil.getItem(STORAGE_MOCK_ENABLED)
      if (mock ? JSON.parse(mock) : isMockEnabled) {
        const { data } = await mockHost.get<UserContextRes>('friday/api/v2/user/$userKey/context')
        return data
      } else {
        const { data } = await getUserContext_v2({
          type: ['vertical-context'],
          AppVersion: appVersion,
          OsType: osType,
        })
        return data
      }
    },
    { useErrorBoundary: false }
  )
}

export { useGetAllServices }
